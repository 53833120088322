import React, { useState } from "react";
import { Input } from "reactstrap";

import * as CONST from "../../CONSTANTS";

export default function email({
  dataObject,
  setDataObject,
  isInputValid,
  setIsInputValid,
}) {
  const [inputValue, setInputValue] = useState('');

  const handleChange = event => {
    const value = event.target.value;

    let _dataObject = { ...dataObject };
    _dataObject[CONST.CN_EMAIL] = value;
    setDataObject(_dataObject);

    setInputValue(value);
    setIsInputValid(true);
  };

  return (
    <div className="name row">
      <div className="col-12 label text-start">Email address</div>
      <div className="col-12 inputs-wrap">
        {!isInputValid ? (
          <div className="alert alert-warning p-2 mb-2">
            Please enter a valid email address
          </div>
        ) : null}

        <div className="row gx-2">
          <div className="col-12">
            <Input
              type="text"
              placeholder=""
              name="Email"
              value={inputValue}
              onChange={evt => handleChange(evt)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
