import React, { useState, useEffect } from "react";

import * as CONST from "../../CONSTANTS";
import DobInput from "../wizard/dobInput";

export default function dateOfBirth({
  dataObject,
  setDataObject,
  isInputValid,
  setIsInputValid,
}) {
  const [dateObject, setDateObject] = useState(CONST.DATE_STATE_STRUCT);
  const [daysValid, setDaysValid] = useState(true);

  useEffect(() => {
    if (
      dateObject[CONST.MONTH][CONST.SELECT_VALUE] !== 0 &&
      dateObject[CONST.DAY][CONST.SELECT_VALUE] !== 0 &&
      dateObject[CONST.YEAR][CONST.SELECT_VALUE] !== 0
    ) {
      if (
        dateObject[CONST.DAY][CONST.SELECT_VALUE] <=
        CONST.DAYS_IN_MONTH[dateObject[CONST.MONTH][CONST.SELECT_VALUE] - 1]
      ) {
        setDaysValid(true);
      } else {
        setDaysValid(false);
      }
    }

    let _dataObject = { ...dataObject };
    _dataObject[CONST.CN_DOB] = { [CONST.DATA]: dateObject };

    setIsInputValid(true);
    setDataObject(_dataObject);
  }, [dateObject]);

  return (
    <div className="row date-of-birth">
      <div className="label text-start col-12">Date of birth</div>
      <div className="col-12 inputs-wrap">
        {!isInputValid ? (
          <div className="alert alert-warning p-2 mb-2">
            Please enter your date of birth
          </div>
        ) : null}
        {!daysValid ? (
          <div className="alert alert-warning p-2 mb-2">
            The selected month does not contain this day
          </div>
        ) : null}
        <DobInput dateObject={dateObject} setDateObject={setDateObject} />
      </div>
    </div>
  );
}
