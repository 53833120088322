import React, { useRef } from "react";

import { Button } from "reactstrap";
import { CONTINUE } from "../../CONSTANTS";
import ChangeCNOnKeyPress from "./changeCNOnKeyPress";

export default function nextButton({ onNextClick }) {
  const nextButtonEl = useRef(null);

  return (
    <Button
      innerRef={nextButtonEl}
      size="lg"
      color="primary"
      value={CONTINUE}
      onClick={() => onNextClick()}
      className="next-button"
    >
      {CONTINUE}
      <svg
        width="2em"
        height="1.4em"
        viewBox="0 0 16 16"
        className="bi bi-chevron-compact-right"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"
        />
      </svg>
      <ChangeCNOnKeyPress _nextButtonRef={nextButtonEl} />
    </Button>
  );
}
