import React, { useState, useRef } from "react";
import { Button } from "reactstrap";

import * as CONST from "../../CONSTANTS";
import ChangeCNOnKeyPress from "../wizard/changeCNOnKeyPress";

import FullName from "../oneStep/fullName";
import Gender from "../oneStep/gender";
import DateOfBirth from "../oneStep/dateOfBirth";
import Email from "../oneStep/email";
import Phone from "../oneStep/phone";
import Address from "../oneStep/address";
import Tcpa from "../oneStep/tcpa";

export default function onestepForm({ handleNextClick }) {
  const NEXT_COMPONENT = CONST.CN_SEND_LEAD;

  const nextButtonEl = useRef(null);

  const [dataObject, setDataObject] = useState({});

  const [isNameValid, setIsNameValid] = useState(true);
  const [isGenderValid, setIsGenderValid] = useState(true);
  const [isDobValid, setIsDobValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isAddressValid, setIsAddressValid] = useState(true);

  const canContinue = () => {
    //this function validates all the given values
    let valid = true;

    //validate name
    if (
      !dataObject[CONST.CN_NAME] ||
      dataObject[CONST.CN_NAME][CONST.NAME_FNAME] === "" ||
      dataObject[CONST.CN_NAME][CONST.NAME_FNAME] === ""
    ) {
      setIsNameValid(false);
      valid = false;
    }

    //validate gender
    if (!dataObject[CONST.CN_GENDER]) {
      setIsGenderValid(false);
      valid = false;
    }

    //validate dob
    if (
      !(
        dataObject[CONST.CN_DOB] &&
        dataObject[CONST.CN_DOB][CONST.DATA] &&
        dataObject[CONST.CN_DOB][CONST.DATA][CONST.MONTH][CONST.SELECT_VALUE] !== 0 &&
        dataObject[CONST.CN_DOB][CONST.DATA][CONST.DAY][CONST.SELECT_VALUE] !== 0 &&
        dataObject[CONST.CN_DOB][CONST.DATA][CONST.YEAR][CONST.SELECT_VALUE] !== 0
      )
    ) {
      setIsDobValid(false);
      valid = false;
    }

    //validate email
    if (
      !(
        dataObject[CONST.CN_EMAIL] &&
        CONST.REGEX_EMAIL.test(dataObject[CONST.CN_EMAIL])
      )
    ) {
      setIsEmailValid(false);
      valid = false;
    }

    //validate phone
    if (
      !(
        dataObject[CONST.CN_PHONE] &&
        CONST.REGEX_PHONE.test(dataObject[CONST.CN_PHONE])
      )
    ) {
      setIsPhoneValid(false);
      valid = false;
    }

    //validate address
    if (
      !(
        dataObject[CONST.CN_ADDRESS] &&
        dataObject[CONST.CN_ADDRESS][CONST.DATA] &&
        dataObject[CONST.CN_ADDRESS][CONST.DATA][CONST.ADDRESS_STREET] !== "" 
      )
    ) {
      setIsAddressValid(false);
      valid = false;
    }

    return valid;
  };

  const onNextClick = () => {
    if (canContinue()) {
      let componentInfo = {};

      componentInfo[CONST.NEXT_COMPONENT] = NEXT_COMPONENT;
      componentInfo[CONST.DATA] = dataObject;

      handleNextClick(componentInfo);
    } else {
      //todo
    }
  };

  return (
    <div>
      <FullName
        dataObject={dataObject}
        setDataObject={setDataObject}
        isInputValid={isNameValid}
        setIsInputValid={setIsNameValid}
      />

      <Gender
        dataObject={dataObject}
        setDataObject={setDataObject}
        isInputValid={isGenderValid}
        setIsInputValid={setIsGenderValid}
      />

      <DateOfBirth
        dataObject={dataObject}
        setDataObject={setDataObject}
        isInputValid={isDobValid}
        setIsInputValid={setIsDobValid}
      />

      <Email
        dataObject={dataObject}
        setDataObject={setDataObject}
        isInputValid={isEmailValid}
        setIsInputValid={setIsEmailValid}
      />

      <Phone
        dataObject={dataObject}
        setDataObject={setDataObject}
        isInputValid={isPhoneValid}
        setIsInputValid={setIsPhoneValid}
      />

      <Address
        dataObject={dataObject}
        setDataObject={setDataObject}
        isInputValid={isAddressValid}
        setIsInputValid={setIsAddressValid}
      />

      <Button
        innerRef={nextButtonEl}
        size="lg"
        color="primary"
        value="SUBMIT"
        onClick={() => onNextClick()}
        className="next-button mb-4"
      >
        SUBMIT
        <ChangeCNOnKeyPress _nextButtonRef={nextButtonEl} />
      </Button>

      <Tcpa />
    </div>
  );
}
