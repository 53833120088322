import React, { useState, useEffect } from "react";
import { Input } from "reactstrap";

import * as CONST from "../../CONSTANTS";
//import StateSelect from "../wizard/stateSelect";

export default function address({
  dataObject,
  setDataObject,
  isInputValid,
  setIsInputValid,
}) {
  let stateTemplate = {};
  stateTemplate[CONST.ADDRESS_STREET] = "";
  stateTemplate[CONST.ADDRESS_CITY] = "";
  stateTemplate[CONST.ADDRESS_STATE] = "";

  const [completeAddress, setCompleteAddress] = useState(stateTemplate);

  const handleChange = evt => {
    const { name, value } = evt.target;
    let _completeAddress = { ...completeAddress };

    _completeAddress[name] = value;
    setIsInputValid(true);
    setCompleteAddress(_completeAddress);
  };

  useEffect(() => {
    //setIsInputValid(true);

    let _dataObject = { ...dataObject };
    _dataObject[CONST.CN_ADDRESS] = { [CONST.DATA]: completeAddress };
    setDataObject(_dataObject);
  }, [completeAddress]);

  return (
    <div className="address row">
      <div className="col-12 label text-start">Address</div>
      <div className="col-12 inputs-wrap">
        {!isInputValid ? (
          <div className="alert alert-warning p-2 mb-2">
            Please fill in your address
          </div>
        ) : null}

        <div className="row">
          <div className="col-12">
            <Input
              type="text"
              placeholder="Street address"
              name={CONST.ADDRESS_STREET}
              value={completeAddress[CONST.ADDRESS_STREET]}
              onChange={evt => handleChange(evt)}
            />
          </div>
        </div>
        {/* <div className="row gx-2">
          <div className="col-4">
            <Input
              type="text"
              placeholder="City"
              name={CONST.ADDRESS_CITY}
              value={completeAddress[CONST.ADDRESS_CITY]}
              onChange={evt => handleChange(evt)}
            />
          </div>
          <div className="col-8">
            <StateSelect
              completeAddress={completeAddress}
              setCompleteAddress={setCompleteAddress}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
}
