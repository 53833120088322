import React, { useEffect, useContext } from "react";
import * as CONST from "../../CONSTANTS";

import { SubmitContext } from "../../context/submitContext";
import { sendLeadToLeadspedia } from "./../thirdParty/leadspedia";
// import { sendLeadToSlinky } from "../thirdParty/slinky";

export default function sendLead({ fData, handleNextClick }) {
  const { setLeadSent } = useContext(SubmitContext);

  useEffect(() => {
    setLeadSent();
    // sendLeadToSlinky(fData);
    sendLeadToLeadspedia(fData).then(({ postSuccess, leadID, leadPrice }) => {
      console.log(postSuccess, leadID);
      handleLeadspediaPostSuccess(postSuccess, leadID, leadPrice);
    });
  }, []);

  const handleLeadspediaPostSuccess = (
    leadspediaPostSuccess,
    leadID,
    leadPrice
  ) => {
    let componentState = {};
    componentState[CONST.DATA] = {
      leadID: leadID,
      leadPrice: leadPrice,
    }

    let componentInfo = {};
    componentInfo[CONST.DATA] = componentState;

    if (leadspediaPostSuccess === true) {
      componentInfo[CONST.NEXT_COMPONENT] = CONST.CN_THANKS;
    } else {
      componentInfo[CONST.NEXT_COMPONENT] = CONST.CN_POSTING_ERROR;
    }

    handleNextClick(componentInfo);
  };

  return (
    <>
      <div className="thankyou row">
        <div className="col-12 query">
          <h2 className="thankyou-heading text-primary">
            Your request is being processes, stand by.
          </h2>
        </div>
      </div>
    </>
  );
}
