import React, { useEffect, useState } from "react";
import {
  getCurrentPhoneNumber,
  getCurrentPhoneNumberFormatted,
  defaultPhoneNumber,
  defaultPhoneNumberFormatted
} from "../thirdParty/leadspedia";

export default function tcpa() {
  const [phoneNumber, setPhoneNumber] = useState(defaultPhoneNumber);
  const [phoneNumberFormatted, setPhoneNumberFormatted] = useState(defaultPhoneNumberFormatted);

  useEffect(() => {
    setPhoneNumber(getCurrentPhoneNumber());
    setPhoneNumberFormatted(getCurrentPhoneNumberFormatted());
  }, []);

  return (
    <div className="tcpa row">
      <div className="col-12 tcpa">
        <label className="tcpa-label">
          <input
            type="hidden"
            id="leadid_tcpa_disclosure"
            name="tcpa_disclosure"
          />
          <span>
            By clicking the button and submitting this form, I agree that I am
            18+ years old and agree to the{" "}
            <a target="_blank" href="https://healthy-quotes.com/privacy-policy" rel="noreferrer">
              <strong>Privacy Policy</strong>
            </a>{" "}
            and{" "}
            <a target="_blank" href="https://healthy-quotes.com/terms-and-conditions" rel="noreferrer">
              <strong>Terms & Conditions</strong>
            </a>
            . By clicking the button and submitting this form, I provide my
            signature giving express consent to receive marketing communications
            via automated telephone dialing systems, artificial or pre-recorded
            voices, emails, live phone calls, pre-recorded calls, postal mail,
            text messages via SMS or MMS and other forms of communication
            regarding offers for Medicare Supplement, Medicare Advantage, Part
            D, Health Insurance, ACA and other senior home, health and
            assistance products from Lendmarx and/or one or more of its{" "}
            <a
              target="_blank"
              href="https://healthy-quotes.com/tcpa"
              rel="noreferrer"
            >
              <strong>marketing partners</strong>
            </a>{" "}
            and agents to the number(s) and/or email I provided, including a
            mobile phone, even if I am on a state or federal Do Not Call and/or
            Do Not Email registry. Agents are not connected with or endorsed by
            the U.S. government or the federal Medicare program. The list of
            companies participating are subject to change. I understand my
            wireless carrier may impose charges for calls or texts. I understand
            that my consent to receive communications is not a condition of
            purchase and I may revoke my consent at any time. To receive quotes
            without providing consent, please call{" "}
            <a href={`tel:${phoneNumber}`}>
              <strong>{phoneNumberFormatted}</strong>
            </a>
            .
          </span>
        </label>
      </div>
    </div>
  );
}
