import React, { useEffect } from "react";

export default function changeCNOnKeyPress({
  _nextButtonRef,
  _isNextAllowedOnKeyPress = true,
}) {
  const handleKeyDown = evt => {
    const { keyCode } = evt;
    if (keyCode === 13 && _nextButtonRef && _nextButtonRef.current) {
      if (_isNextAllowedOnKeyPress) {
        console.log("enter pressed");
        _nextButtonRef.current.click();
        evt.preventDefault();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  return <></>;
}
