import React, { useState } from "react";
import { Input } from "reactstrap";

import * as CONST from "../../CONSTANTS";

export default function fullName({
  dataObject,
  setDataObject,
  isInputValid,
  setIsInputValid,
}) {
  let stateTemplate = {};
  stateTemplate[CONST.NAME_FNAME] = "";
  stateTemplate[CONST.NAME_LNAME] = "";

  const [fullName, setFullName] = useState(stateTemplate);

  const formatInput = input => {
    let updatedInp = input;
    updatedInp = updatedInp.replace(/[^A-Za-z]/gi, "");

    if (updatedInp.length > 20)
      updatedInp = updatedInp.slice(0, updatedInp.length - 1);

    return updatedInp;
  };

  const handleChange = event => {
    const { name, value } = event.target;
    let _fullName = { ...fullName };
    _fullName[name] = formatInput(value);
    setIsInputValid(true);
    setFullName(_fullName);

    let _dataObject = { ...dataObject };
    _dataObject[CONST.CN_NAME] = { [CONST.DATA]: _fullName };
    setDataObject(_dataObject);
  };

  return (
    <div className="name row">
      <div className="col-12 label text-start">Full name</div>
      <div className="col-12 inputs-wrap">
        {!isInputValid ? (
          <div className="alert alert-warning p-2 mb-2">
            Please enter first and last name
          </div>
        ) : null}

        <div className="row gx-2">
          <div className="col-6">
            <Input
              type="text"
              placeholder="First Name"
              name={CONST.NAME_FNAME}
              value={fullName[CONST.NAME_FNAME]}
              onChange={evt => handleChange(evt)}
            />
          </div>
          <div className="col-6">
            <Input
              type="text"
              placeholder="Last Name"
              name={CONST.NAME_LNAME}
              value={fullName[CONST.NAME_LNAME]}
              onChange={evt => handleChange(evt)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
