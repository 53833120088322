import React from "react";

import * as CONST from "../../CONSTANTS";
import Select from "react-select";

const getObjectInReactSelectFormat = val => {
  return { [CONST.SELECT_VALUE]: val, [CONST.SELECT_LABEL]: val };
};

let DAYS_LIST = [];
for (let i = 1; i < 32; i++) {
  DAYS_LIST.push(getObjectInReactSelectFormat(i));
}

let YEARS_LIST = [];
let dt = new Date();
const startYear = dt.getFullYear();
const endYear = startYear - 100;
for (let i = startYear; i >= endYear; i--) {
  YEARS_LIST.push(getObjectInReactSelectFormat(i));
}

export default function dobInput({ dateObject, setDateObject }) {
  const handleChange = (controlName, selectedOption) => {
    let dateObj = { ...dateObject };
    dateObj[controlName] = selectedOption;

    setDateObject(dateObj);
  };

  return (
    <div className="dob-input row gx-2">
      <Select
        value={dateObject[CONST.MONTH]}
        options={CONST.MONTHS_LIST}
        onChange={selectedOpt => handleChange(CONST.MONTH, selectedOpt)}
        className="col-4"
        styles={{ textAlign: "left" }}
      />
      <Select
        value={dateObject[CONST.DAY]}
        options={DAYS_LIST}
        onChange={selectedOpt => handleChange(CONST.DAY, selectedOpt)}
        className="col-4"
      />
      <Select
        value={dateObject[CONST.YEAR]}
        options={YEARS_LIST}
        onChange={selectedOpt => handleChange(CONST.YEAR, selectedOpt)}
        className="col-4"
      />
    </div>
  );
}
