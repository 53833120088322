import React from "react";

import * as CONST from "../../CONSTANTS";

export default function gender({
  dataObject,
  setDataObject,
  isInputValid,
  setIsInputValid,
}) {
  const handleChange = event => {
    const { value } = event.target;
    setIsInputValid(true);

    let _dataObject = { ...dataObject };
    _dataObject[CONST.CN_GENDER] = value;
    setDataObject(_dataObject);
  };

  return (
    <>
      {!isInputValid ? (
        <div className="alert alert-warning p-2 mb-2">
          Please enter your gender
        </div>
      ) : null}
      <div className="gender radius-box row flex-nowrap">
        <div className="col-12 d-flex inputs-wrap">
          <span className="label pe-4">Gender:</span>
          <div className="text-start">
            <label className="d-inline-block">
              <input
                className="me-2"
                type="radio"
                value={CONST.GENDER_MALE}
                checked={dataObject[CONST.CN_GENDER] === CONST.GENDER_MALE}
                onChange={handleChange}
              />
              Male
            </label>
            <label className="ps-4 d-inline-block">
              <input
                className="me-2"
                type="radio"
                value={CONST.GENDER_FEMALE}
                checked={dataObject[CONST.CN_GENDER] === CONST.GENDER_FEMALE}
                onChange={handleChange}
              />
              Female
            </label>
          </div>
        </div>
      </div>
    </>
  );
}
