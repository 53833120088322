import React, { useState } from "react";
import { Input } from "reactstrap";

import * as CONST from "../../CONSTANTS";

export default function phone({
  dataObject,
  setDataObject,
  isInputValid,
  setIsInputValid,
}) {
  const [inputValue, setInputValue] = useState("");

  const sanitizeInput = input => {
    let updatedInp = input;
    let lastInpChar = updatedInp.slice(updatedInp.length - 1);

    lastInpChar = lastInpChar.replace(/[^0-9]/gi, "");
    updatedInp = updatedInp
      .substring(0, updatedInp.length - 1)
      .concat(lastInpChar);

    // Changed to (555)123-4567
    //if (updatedInp.length > 14) updatedInp = updatedInp.slice(0, updatedInp.length - 1);
    if (updatedInp.length > 10)
      updatedInp = updatedInp.slice(0, updatedInp.length - 1);

    return updatedInp;
  };

  const handleChange = event => {
    const value = sanitizeInput(event.target.value);

    let _dataObject = { ...dataObject };
    _dataObject[CONST.CN_PHONE] = value;
    setDataObject(_dataObject);

    setIsInputValid(true);
    setInputValue(value);
  };

  return (
    <div className="name row">
      <div className="col-12 label text-start">Phone number</div>
      <div className="col-12 inputs-wrap">
        {!isInputValid ? (
          <div className="alert alert-warning p-2 mb-2">
            Please enter a valid phone number
          </div>
        ) : null}

        <div className="row gx-2">
          <div className="col-12">
            <Input
              type="text"
              placeholder=""
              name="Phone"
              value={inputValue}
              onChange={evt => handleChange(evt)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
