function initTrustedform() {
  let field = "xxTrustedFormCertUrl";
  let provideReferrer = false;
  let invertFieldSensitivity = false;
  let sandbox = false;
  let tf = document.createElement("script");
  tf.type = "text/javascript";
  tf.async = true;
  tf.src =
    "http" +
    ("https:" == document.location.protocol ? "s" : "") +
    "://api.trustedform.com/trustedform.js?provide_referrer=" +
    escape(provideReferrer) +
    "&field=" +
    escape(field) +
    "&l=" +
    new Date().getTime() +
    Math.random() +
    "&invert_field_sensitivity=" +
    invertFieldSensitivity +
    "&sandbox=" +
    sandbox;
  let s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(tf, s);
}

export { initTrustedform };
